import type { bottom } from '@popperjs/core';
<script setup lang="ts">
import { usePageStore } from '@voix/store/pageStore'

defineProps({
  footerCompanyMenu: {
    type: Object,
    default: () => null,
  },
  footerResourcesMenu: {
    type: Object,
    default: () => null,
  },
  footerTermsMenu: {
    type: Object,
    default: () => null,
  },
  config: {
    type: Object,
    default: () => ({
      crossPromotion: true,
      signUpForm: true,
      termsMenuPosition: 'left',
    }),
  },

})

const currentResort: any = inject('currentResort')
const accentColor: any = inject('accentColor')

const { currentPage } = usePageStore()
const currentYear = computed(() => new Date().getFullYear())

const footerSettings = computed(() => {
  if (currentResort)
    return currentResort.footer_settings
})

const isVisible = ref(false)
function visibilityChanged(entry: { intersectionRatio: number }) {
  if (entry.intersectionRatio * 100 > 0)
    isVisible.value = true
}

const hasOneSocialElement = computed(() => {
  if (!currentResort)
    return false

  return (
    currentResort.facebook_url
    || currentResort.youtube_url
    || currentResort.twitter_url
    || currentResort.instagram_url
    || currentResort.tiktok_url
  )
})
</script>

<template>
  <div v-if="currentResort" id="footer" name="footer" class="py-10 relative z-10">
    <!-- TODO: use hydration method rather than visibility directive -->
    <span v-voix-visibility="{ callback: visibilityChanged, threshold: [0, .25] }" />
    <div
      v-if="config.crossPromotion"
      class="top flex justify-center"
      :style="{
        'background-color': footerSettings.top.background,
        'color': footerSettings.top.foreground,
      }"
    >
      <div class="py-8 px-4 md:px-12 text-center">
        <div
          class="relative z-10 inline-block p-8"
          :style="{ 'background-color': footerSettings.top.background }"
        >
          <img src="/imgs/footer/10-years-playa-bw.png" loading="lazy" width="200" height="86" alt="Playa Resorts Logo">
        </div>

        <div
          v-if="isVisible"
          class="relative grid grid-cols-2 md:grid-cols-4 lg:grid-cols-6 gap-4 border-t border-b lg:-mt-16 mb-4 pt-10 pb-8 border-white text-white"
          :style="{ 'border-color': footerSettings.top.foreground }"
        >
          <a
            href="https://www.resortsbyhyatt.com/"
            aria-label="Hyatt Ziva"
            class="text-white justify-self-center self-center"
          >
            <LazyLogosHyattZivaZilara
              :fill="false"
              :w="100"
              :h="50"
              style="height: 50px"
            />
          </a>

          <a
            href="https://hiltonbyplaya.com/"
            aria-label="Resorts By Hilton"
            class="text-white justify-self-center self-center"
          >
            <LazyLogosHilton :fill="false" :w="100" :h="50" style="height: 55px" />
          </a>

          <a
            href="https://alltrabyplaya.com/"
            aria-label="Wyndham Alltra"
            class="text-white justify-self-center self-center"
          >
            <LazyLogosWyndham class="w-48" style="height: 50px" />
          </a>

          <a
            href="https://www.jewelresorts.com/"
            aria-label="Jewel Resorts"
            class="text-white justify-self-center self-center"
          >
            <LazyLogosJewel
              :fill="false" :w="100" :h="50"
              style="height: 50px"
            />
          </a>

          <a
            href="https://sanctuarycapcana.com/"
            aria-label="Sanctuary Capcana"
            class="text-white justify-self-center self-center"
          >
            <LazyLogosMarriotBonvoy
              :fill="false"
              :w="100"
              :h="50"
              style="height: 25px"
            />
          </a>

          <a
            href="https://hiltonbyplaya.com/the-yucatan-resort-playa-del-carmen/"
            aria-label="Jewel Grande"
            class="pt-4 text-white"
          >
            <LazyLogosTapestryCollection
              :fill="false"
              :w="100"
              style="height: 50px"
              :h="50"
            />
          </a>

          <div class="hidden lg:flex absolute left-0 right-0 bottom-[-30px] justify-center w-full">
            <a
              class="lg:inline-block px-6 py-4"
              href="https://www.playaresorts.com/"
              target="_blank"
              :style="{ 'background-color': footerSettings.top.background }"
            >
              PLAYARESORTS.COM
            </a>
          </div>
        </div>

        <ul
          v-if="footerCompanyMenu"
          class="mt-12 flex justify-center items-center flex-col md:flex-row text-center p-4 md:divide-x divide-white/50 "
        >
          <li class="md:mr-4">
            <div
              v-cloak
              class="text-lg uppercase opacity-60"
              :style="{ color: footerSettings.top.foreground }"
            >
              {{ $t('the-company') }}
            </div>
          </li>
          <li
            v-for="item in footerCompanyMenu.menu_items"
            :key="item.id"
            class="px-4"
          >
            <a
              v-cloak
              class="md:text-sm"
              :href="item.permalink"
              :style="{ color: footerSettings.top.foreground }"
            >
              {{ item.name }}
            </a>
          </li>
        </ul>

        <ul
          v-if="footerResourcesMenu"
          class="flex justify-center flex-col md:flex-row text-center items-center p-4 md:divide-x divide-white/50 "
        >
          <li class="md:mr-4">
            <div
              v-cloak
              class="text-lg uppercase opacity-60"
              :style="{ color: footerSettings.top.foreground }"
            >
              {{ $t('resources') }}
            </div>
          </li>
          <li
            v-for="item in footerResourcesMenu.menu_items"
            :key="item.id"
            class="px-4"
          >
            <a
              v-cloak
              class="md:text-sm"
              :href="item.permalink"
              :style="{ color: footerSettings.top.foreground }"
            >
              {{ item.name }}
            </a>
          </li>
        </ul>

        <div
          class="flex justify-center flex-col md:flex-row text-center items-center p-4"
        >
          <div
            v-cloak
            class="mb-4 md:mb-0 font-light"
            :style="{ color: footerSettings.top.foreground }"
          >
            {{ $t('follow-us') }}
          </div>

          <ul v-if="isVisible" class="flex justify-center text-center items-center list-reset">
            <li class="mx-1">
              <a
                class="text-sm"
                href="https://www.facebook.com/PlayaResorts/"
                aria-label="Playa Resorts Facebook"
                target="_blank"
                :style="{ color: footerSettings.top.foreground }"
              >
                <LazyIconsFacebook :w="25" :h="25" color="#fff" />
              </a>
            </li>
            <li class="mx-1">
              <a
                class="text-sm"
                href="https://twitter.com/playaresorts"
                aria-label="Playa Resorts Twitter"
                target="_blank"
                :style="{ color: footerSettings.top.foreground }"
              >
                <LazyIconsTwitter :w="25" :h="25" color="#fff" />
              </a>
            </li>
            <li class="mx-1">
              <a
                class="text-sm"
                href="https://www.instagram.com/playaresorts/"
                aria-label="Playa Resorts Instagram"
                target="_blank"
                :style="{ color: footerSettings.top.foreground }"
              >
                <LazyIconsInstagram :w="25" :h="25" color="#fff" />
              </a>
            </li>
            <li class="mx-1">
              <a
                class="text-sm"
                href="https://www.youtube.com/channel/UCEHoZZoXR4lh-Mas2FYx-nQ"
                aria-label="Playa Resorts YouTube"
                target="_blank"
                :style="{ color: footerSettings.top.foreground }"
              >
                <LazyIconsYouTube :w="25" :h="25" color="#fff" />
              </a>
            </li>
            <li class="mx-1">
              <a
                class="text-sm"
                href="https://www.pinterest.com/PlayaResorts/"
                aria-label="Playa Resorts inset-0terest"
                target="_blank"
                :style="{ color: footerSettings.top.foreground }"
              >
                <LazyIconsPinterest :w="25" :h="25" color="#fff" />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div
      class="bottom"
      :style="{
        'background-color': footerSettings.bottom.background,
        'color': footerSettings.bottom.foreground,
      }"
    />

    <div
      v-if="config.signUpForm"
      class="bottom"
      :style="{ backgroundColor: accentColor.value || footerSettings.bottom.background, color: footerSettings.bottom.foreground }"
    >
      <SignUpForm
        form-id="A01904B0-3D23-4A65-B86A-EECBC4BD7A70"
        :language="currentPage?.language_code"
      />
    </div>

    <div v-if="config.socialMenu && hasOneSocialElement" class="flex justify-center text-xs flex-col items-center px-4 lg:px-16 py-10">
      <div
        class="px-4 w-full md:px-10 py-2 border-b border-t border-zilara-cancun-indigo text-center uppercase"
      >
        <div class="flex flex-col lg:flex-row items-center justify-center">
          {{ currentResort }}
          <a
            v-if="currentResort.footer_contact_us_url"
            :href="currentResort.footer_contact_us_url"
            class="mb-6 lg:mb-0 text-grey-darkest text-lg ml-2 pr-4 md:border-r border-black"
          >
            CONTACT US
          </a>
          <span class="mb-2 lg:mb-0 text-grey-darkest text-lg ml-4 mr-2">FOLLOW US:</span>
          <div class="flex">
            <a
              v-if="currentResort.facebook_url"
              class="text-grey-darkest text-sm mx-2"
              :href="currentResort.facebook_url" target="_blank"
            >
              <Icon name="ri:facebook-fill" class="w-10 h-10" />
            </a>
            <a
              v-if="currentResort.youtube_url"
              class="text-grey-darkest text-sm mx-2"
              :href="currentResort.youtube_url" target="_blank"
            >
              <Icon name="mdi:youtube" class="w-10 h-10" />
            </a>
            <a
              v-if="currentResort.twitter_url"
              class="text-grey-darkest text-sm mx-2"
              :href="currentResort.twitter_url" target="_blank"
            >
              <Icon name="devicon:twitter" class="w-10 h-10" />
            </a>
            <a
              v-if="currentResort.instagram_url"
              class="text-grey-darkest text-sm mx-2"
              :href="currentResort.instagram_url" target="_blank"
            >
              <Icon name="mdi:instagram" class="w-10 h-10" />
            </a>

            <a
              v-if="currentResort.tiktok_url"
              class="text-grey-darkest text-sm mx-2"
              :href="currentResort.tiktok_url" target="_blank"
            >
              <Icon name="ic:baseline-tiktok" class="w-10 h-10" />
            </a>
          </div>
        </div>
      </div>
    </div>

    <div
      class="flex flex-col justify-between p-4 text-xs"
      :class="{
        'lg:flex-row': config.termsMenuPosition === 'left' || !config.termsMenuPosition,
      }"
    >
      <div>
        <ul
          class="flex flex-col md:flex-row items-center list-reset"
          :class="{
            'justify-center': config.termsMenuPosition === 'center',
          }"
        >
          <li v-cloak class="my-2 md:my-0 mx-2">
            <a class="text-xs text-grey-darker" :href="`https://www.playaresorts.com${$t('path')}terms-conditions`">
              {{ $t('terms-and-conditions') }}
            </a>
          </li>
          <li v-cloak class="my-2 md:my-0 mx-2">
            <a class="text-xs text-grey-darker" :href="`https://www.playaresorts.com${$t('path')}privacy-policy`">
              {{ $t('privacy-policy') }}
            </a>
          </li>
          <li v-cloak class="my-2 md:my-0 mx-2">
            <a class="text-xs text-grey-darker" href="https://www.playaresorts.com/accessibility">
              {{ $t('accessibility') }}
            </a>
          </li>
          <li v-cloak class="my-2 md:my-0 mx-2">
            <a
              id="ot-sdk-btn" class="text-xs text-grey-darker ot-sdk-show-settings p-0"
              href="javascript:void(0)"
            >
              {{ $t('cookie-settings') }}
            </a>
          </li>
          <li v-cloak class="my-2 md:my-0 mx-2">
            <a class="text-xs text-grey-darker" href="https://agentcashplus.com/">
              {{ $t('travel-professionals') }}
            </a>
          </li>
        </ul>
      </div>
      <slot name="bottomSlot" />

      <div class="text-center">
        &copy; {{ currentYear }} Playa Management USA, LLC All rights reserved
      </div>
    </div>
  </div>
</template>
